




























































import Component, { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"
import InputMixin from "@/types/InputMixin"
import EstimationApi from "@/api/Estimation/EstimationApi"
import EstimationProductCategory from "@/types/Estimation/EstimationProductCategory"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import EstimationProductGroup from "@/types/Estimation/EstimationProductGroup"
import { Watch } from "vue-property-decorator"

@Component({
  name: "CalculateCategoryList"
})
export default class CalculateCategoryList extends mixins(BreakpointMixin, InputMixin) {
  private productGroups: EstimationProductGroup[] = []

  private categories: EstimationProductCategory[] = []

  private groupId = 1

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    EstimationApi.listProductGroups()
      .then(({ data }) => {
        this.productGroups = _.map(data, (d) => deserialize<EstimationProductGroup>(d, EstimationProductGroup))
        // @ts-ignore
        this.groupId = parseInt(this.$route.query.group, 10)
      })
      .then(this.fetchCategories)
  }

  private mounted() {
    this.fetchData()
  }

  @Watch("groupId")
  onGroupChange() {
    this.fetchCategories()
  }

  private fetchCategories() {
    EstimationApi.listProductCategories(this.groupId).then(({ data }) => {
      this.categories = _.map(data, (d) => deserialize<EstimationProductCategory>(d, EstimationProductCategory))
    })
  }

  // private viewDetail(catId: number) {
  //   const lang = this.$i18n.locale
  //   this.$router.push({
  //     name: "CalculateDetail",
  //     query: {
  //       group: `${this.groupId}`,
  //       category: `${catId}`,
  //       lang
  //     }
  //   })
  // }

  private generateRoute(catId: number) {
    return {
      name: "CalculateDetail",
      query: {
        group: `${this.groupId}`,
        category: `${catId}`,
        lang: this.$i18n.locale
      }
    }
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 392
    }
    if (bp.mdAndUp) {
      return 275
    }
    return 275
  }

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.smAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }
}
